@font-face {
	font-family: 'Lato-Light';
	src: local('Lato Light'), local('Lato-Light'),
		url(./LatoLatin-Light.woff2) format('woff2'),
		url(./LatoLatin-Light.woff) format('woff'),
		url(./LatoLatin-Light.ttf) format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Lato-Regular';
	src: local('Lato Regular'), local('Lato-Regular'),
		url(./LatoLatin-Regular.woff2) format('woff2'),
		url(./LatoLatin-Regular.woff) format('woff'),
		url(./LatoLatin-Regular.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Lato-Italic';
	src: local('Lato Italic'), local('Lato-Italic'),
		url(./LatoLatin-Italic.woff2) format('woff2'),
		url(./LatoLatin-Italic.woff) format('woff'),
		url(./LatoLatin-Italic.ttf) format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Lato-Bold';
	src: local('Lato Bold'), local('Lato-Bold'),
		url(./LatoLatin-Bold.woff2) format('woff2'),
		url(./LatoLatin-Bold.woff) format('woff'),
		url(./LatoLatin-Bold.ttf) format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Lato-Black';
	src: local('Lato Black'), local('Lato-Black'),
		url(./LatoLatin-Black.woff2) format('woff2'),
		url(./LatoLatin-Black.woff) format('woff'),
		url(./LatoLatin-Black.ttf);
	font-weight: 900;
	font-style: normal;
}
